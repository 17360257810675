import {
    useState,
    useEffect,
    Suspense,
    lazy,
    useRef,
    memo,
    type FC,
    type ReactNode,
    useCallback,
    useMemo,
} from 'react';
import { useFragment, graphql, useRelayEnvironment } from 'react-relay';
import classNames from 'classnames';

import FolderIcon from 'dibs-icons/exports/legacy/Folder';
import HeartIcon from 'dibs-icons/exports/legacy/HeartFilled';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';
import { keys } from 'dibs-ts-utils/exports/keys';

import { HeartWrapperLazy } from '../../../global/HeartWrapperLazy';
import { FolderWrapperLazy } from '../../../global/FolderWrapperLazy';
import {
    authLabels,
    folderTrackingHooks,
    trackHeartFavorited,
    trackHeartUnfavorited,
    type FavoritesLocation,
} from '../../../utils/tracking/shared/favoritesTracking';
import { loadAddToFolderModalWrapper } from '../../../global/loadAddToFolderModalWrapper';
import {
    SAVE_ITEM_FLOW,
    SAVE_ITEM_TO_FOLDER_FLOW,
    sharedLoginCheck,
} from '../../../authentication/sharedLoginCheck';
import {
    FORCE_FAVORITE_ITEM_ID,
    FORCE_FOLDER_ITEM_ID,
    setForceFavoriteItemId,
    getForceFavoriteItemId,
    clearForceFavoriteItemId,
    setAuctionPromptDismissedTimestamp,
} from './localStorageHelpers';

import { type FavoritesItem_item$key } from './__generated__/FavoritesItem_item.graphql';
import { type FavoritesProviderQuery$data } from './__generated__/FavoritesProviderQuery.graphql';

import styles from './FavoriteIcons.scss';
import { getUserEventItem, trackUserEventItemAddToFavorites } from 'dibs-tracking';

const FavoritesTooltipLazy = lazy(
    () => import(/* webpackChunkName: "FavoritesTooltip" */ './FavoritesTooltip')
);

const itemFragment = graphql`
    fragment FavoritesItem_item on Item {
        serviceId
        title
        ecommerceTrackingParams
    }
`;

type FolderTrackingHooks = typeof folderTrackingHooks;

type Props = {
    itemImage?: string | null;
    location: FavoritesLocation;
    // passed from FavoritesProvider
    disable?: boolean;
    userId?: string | null;
    userIds: string[];
    itemIds: string[];
    singlePortfolioItem: boolean;
    loadPortfolioItemData: boolean;
    viewer?: FavoritesProviderQuery$data['viewer'] | null;
    fetchHeart: boolean;
    fetchFolder: boolean;
    heartIconClass?: string;
    heartIconWrapperClass?: string;
    folderIconClass?: string;
    folderIconWrapperClass?: string;
    wrapperClass?: string;
    item: FavoritesItem_item$key | null | undefined;
    showFolderPlusIcon?: boolean;
    showTooltip?: boolean;
    onUnFavorited?: () => void;
    onFolderActionStart?: () => void;
    onFolderActionEnd?: () => void;
    animateHeartPulse?: boolean;
    isForceFavoriteItem?: boolean;
    setIsForceFavoriteItem?: (isForceFavoriteItem: boolean) => void;
    onIsFilledChange?: (isFilled: boolean) => void;
    wrapperComponent?: 'div' | 'Link';
    isNewListing?: boolean;
    isSponsored?: boolean;
};

export const FavoritesItem: FC<Props> = memo(
    ({
        isNewListing,
        isSponsored,
        itemImage,
        heartIconClass,
        heartIconWrapperClass,
        folderIconClass,
        folderIconWrapperClass,
        wrapperClass,
        item: itemRef,
        showFolderPlusIcon,
        showTooltip,
        onUnFavorited,
        onFolderActionStart,
        onFolderActionEnd,
        animateHeartPulse,
        isForceFavoriteItem,
        setIsForceFavoriteItem,
        onIsFilledChange,
        // passed from FavoritesProvider
        location,
        disable,
        userId,
        userIds,
        itemIds,
        singlePortfolioItem,
        loadPortfolioItemData,
        viewer,
        fetchHeart = true,
        fetchFolder = true,
        wrapperComponent,
    }) => {
        const relayEnvironment = useRelayEnvironment();
        const item = useFragment(itemFragment, itemRef);
        const [openAddToFolderModal, setOpenAddToFolderModal] = useState(false);
        const favoritesRef = useRef(null);
        const { title: itemTitle, serviceId: itemId, ecommerceTrackingParams } = item || {};

        const trackingPrice = ecommerceTrackingParams?.price || '';

        const userEventItem = useMemo(
            () => getUserEventItem({ id: itemId, amount: trackingPrice }),
            [itemId, trackingPrice]
        );

        const trackUserAddedItemToFavorites = useCallback(() => {
            if (userEventItem) {
                trackUserEventItemAddToFavorites(relayEnvironment, [userEventItem]);
            }
        }, [userEventItem, relayEnvironment]);

        useEffect(() => {
            const shouldOpenAddToFolderModal =
                getForceFavoriteItemId(FORCE_FOLDER_ITEM_ID) === itemId;

            if (shouldOpenAddToFolderModal) {
                setOpenAddToFolderModal(true);
                clearForceFavoriteItemId(FORCE_FOLDER_ITEM_ID);
            }
        }, [itemId]);

        const onAddItemSuccess = useCallback((): void => {
            if (authModalLoader.isPaused()) {
                authModalLoader.resume();
            }

            trackUserAddedItemToFavorites();

            if (onFolderActionEnd) {
                onFolderActionEnd();
            }
        }, [onFolderActionEnd, trackUserAddedItemToFavorites]);

        const getFolderTrackingHooks = useCallback((): FolderTrackingHooks => {
            let hooks = folderTrackingHooks;
            if (location) {
                hooks = keys(folderTrackingHooks).reduce((map, current) => {
                    // @ts-ignore Target signature provides too few arguments. Expected 3 or more, but got 1.
                    map[current] = folderTrackingHooks[current].bind(null, location);
                    return map;
                }, {} as FolderTrackingHooks);
            }

            return hooks;
        }, [location]);

        const showAuthModal = useCallback(
            ({
                flow,
                onSuccess,
                onError,
                shouldPauseAuthFlow,
            }: {
                flow: typeof SAVE_ITEM_TO_FOLDER_FLOW | typeof SAVE_ITEM_FLOW;
                onSuccess: ({
                    userId,
                    isVerifiedTrade,
                }: {
                    userId: string;
                    isVerifiedTrade?: boolean;
                }) => void;
                onError?: (reason?: string) => void;
                shouldPauseAuthFlow?: (state: $TSFixMe) => boolean;
            }): void => {
                sharedLoginCheck({
                    onSuccess,
                    onError,
                    authOptions: {
                        flow,
                        shouldPauseAuthFlow,
                        ga: { label: hasKey(authLabels, location) ? authLabels[location] : '' },
                        additionalProps: {
                            item: { firstPhotoWebPath: itemImage, title: itemTitle },
                        },
                    },
                });
            },
            [itemTitle, itemImage, location]
        );

        const getFolderModalComponent = useCallback((): Promise<void> => {
            const flow = SAVE_ITEM_TO_FOLDER_FLOW;

            return new Promise((resolve, reject) => {
                showAuthModal({
                    flow,
                    shouldPauseAuthFlow: (state: $TSFixMe) => state.step === 0,
                    onSuccess: ({
                        userId: authPayloadUserId,
                        isVerifiedTrade: authPayloadIsVerifiedTrade,
                    }) => {
                        if (authPayloadIsVerifiedTrade) {
                            // after trade user logs in, page is reloaded, so there's no point in showing modal
                            setForceFavoriteItemId(FORCE_FOLDER_ITEM_ID, itemId || '');
                            return resolve();
                        }
                        // @ts-ignore loadAddToFolderModalWrapper still in jsx and expects 0 args
                        return loadAddToFolderModalWrapper({ authPayloadUserId }).then(resolve);
                    },
                    onError: reject,
                });
            });
        }, [itemId, showAuthModal]);

        const handleOnUnFavorited = useCallback((): void => {
            onUnFavorited?.();
            trackHeartUnfavorited({
                location,
                itemPk: itemId,
                isSponsored,
                isNewListing,
            });
        }, [itemId, location, onUnFavorited, isSponsored, isNewListing]);

        const handleOnFavorited = useCallback((): void => {
            setAuctionPromptDismissedTimestamp();
            trackHeartFavorited({
                location,
                itemPk: itemId,
                isSponsored,
                isNewListing,
            });
            trackUserAddedItemToFavorites();
        }, [location, itemId, isSponsored, isNewListing, trackUserAddedItemToFavorites]);

        const renderHeart = useCallback((): ReactNode => {
            if (disable) {
                return <HeartIcon className={styles.icon} />;
            }

            return (
                <HeartWrapperLazy
                    load
                    itemId={itemId}
                    userId={userId}
                    viewer={viewer}
                    authModalShow={(cb?: () => void) =>
                        showAuthModal({
                            flow: SAVE_ITEM_FLOW,
                            onSuccess: ({ isVerifiedTrade: authPayloadIsVerifiedTrade }) => {
                                if (authPayloadIsVerifiedTrade) {
                                    setForceFavoriteItemId(FORCE_FAVORITE_ITEM_ID, itemId || '');
                                }
                                if (cb && typeof cb === 'function') {
                                    cb();
                                }
                            },
                        })
                    }
                    onFavorited={handleOnFavorited}
                    onUnFavorited={handleOnUnFavorited}
                    className={heartIconClass}
                    buttonClass={styles.heartButton}
                    theme="dark"
                    animateHeartPulse={animateHeartPulse}
                    forceFavoriteItem={isForceFavoriteItem}
                    onIsFilledChange={onIsFilledChange}
                    setIsForceFavoriteItem={setIsForceFavoriteItem}
                    wrapperComponent={wrapperComponent}
                />
            );
        }, [
            animateHeartPulse,
            disable,
            handleOnFavorited,
            handleOnUnFavorited,
            heartIconClass,
            isForceFavoriteItem,
            itemId,
            onIsFilledChange,
            showAuthModal,
            userId,
            viewer,
            setIsForceFavoriteItem,
            wrapperComponent,
        ]);

        const renderFolder = useCallback((): ReactNode => {
            if (disable) {
                return <FolderIcon className={styles.icon} />;
            }

            return (
                <FolderWrapperLazy
                    load
                    userId={userId}
                    userIds={userIds}
                    primaryItemPk={itemId}
                    selectedItemIds={itemIds}
                    singlePortfolioItem={singlePortfolioItem}
                    loadPortfolioItemData={loadPortfolioItemData}
                    viewer={viewer}
                    environment={relayEnvironment}
                    trackingHooks={getFolderTrackingHooks()}
                    getModalComponent={getFolderModalComponent}
                    additionalIconClass={folderIconClass}
                    onAddItemSuccess={onAddItemSuccess}
                    shouldFillInFolder={!!userId}
                    openOnRender={openAddToFolderModal}
                    theme="dark"
                    showPlusIcon={showFolderPlusIcon}
                    onFolderSave={onFolderActionStart}
                    onFolderRemove={onFolderActionStart}
                    onNewFolderSave={onFolderActionStart}
                    onRemoveItemSuccess={onFolderActionEnd}
                    onFolderClick={onFolderActionStart}
                    closeModal={onFolderActionEnd}
                />
            );
        }, [
            disable,
            folderIconClass,
            getFolderModalComponent,
            getFolderTrackingHooks,
            itemId,
            itemIds,
            loadPortfolioItemData,
            onAddItemSuccess,
            onFolderActionEnd,
            onFolderActionStart,
            openAddToFolderModal,
            relayEnvironment,
            showFolderPlusIcon,
            singlePortfolioItem,
            userId,
            userIds,
            viewer,
        ]);

        if (disable) {
            return null;
        }

        return (
            <div className={classNames(styles.wrap, wrapperClass)} ref={favoritesRef}>
                {showTooltip && (
                    <Suspense fallback="">
                        <FavoritesTooltipLazy elementRef={favoritesRef} />
                    </Suspense>
                )}
                <Suspense fallback="">
                    {fetchHeart && (
                        <div
                            className={classNames(styles.iconWrap, {
                                [heartIconWrapperClass || '']: !!heartIconWrapperClass,
                            })}
                            data-tn="item-save-to-portfolio"
                        >
                            {renderHeart()}
                        </div>
                    )}
                    {fetchFolder && (
                        <div
                            className={classNames(styles.iconWrap, {
                                [folderIconWrapperClass || '']: !!folderIconWrapperClass,
                            })}
                            data-tn="item-save-to-folder"
                        >
                            {renderFolder()}
                        </div>
                    )}
                </Suspense>
            </div>
        );
    }
);
